<template>
  <div>
    <Drawer :drawer="drawer" :withHeader="false" :size="600" @close="close">
      <div v-loading="loading" class="view">
        <div class="header">
          <div class="left">
            <span class="title">工单详情</span>
            <p class="workbill-no">工单编号：{{ rowData.workOrderNo }}</p>
          </div>
          <i class="el-icon-close icon" @click="close" />
        </div>
        <div class="bill-top">
          <div class="item">
            <p class="label">客户：</p>
            <p class="value">{{ rowData.companyName }}</p>
          </div>
          <div class="item">
            <p class="label">问题大类：</p>
            <p class="value">{{ rowData.workOrderTypeName }}</p>
          </div>
          <!-- <div class="item">
            <p class="label">工单流向：</p>
            <p class="value">{{ rowData.workOrderNo }}</p>
          </div> -->
          <div class="item">
            <p class="label">问题细类：</p>
            <p class="value">{{ rowData.workOrderItemName }}</p>
          </div>
          <div class="item">
            <p class="label">工单状态：</p>
            <p class="value status" :style="needStyle(rowData.status)">
              {{ rowData.status | formatterStatus }}
            </p>
          </div>
          <div class="item">
            <p class="label">优先级：</p>
            <p class="value" :style="levelStyle(rowData.priorityLevel)">
              {{ rowData.priorityLevelName }}
            </p>
          </div>
          <div class="item">
            <p class="label">提交人：</p>
            <p class="value">{{ rowData.createdName }}</p>
          </div>
          <div class="item">
            <p class="label">参考号码：</p>
            <p class="value">{{ rowData.referenceNumber }}</p>
          </div>
          <div class="item">
            <p class="label">提交时间：</p>
            <p class="value">{{ rowData.createdOn ? rowData.createdOn.slice(0, rowData.createdOn.length - 3) : '' }}</p>
          </div>
          <div class="item">
            <p class="label">问题描述：</p>
            <p class="value">{{ rowData.description }}</p>
          </div>
          <div v-if="jsonData" class="item">
            <p class="label">附加数据：</p>
            <p class="value" style="color: #66b1ff;cursor: pointer;" @click="dialogVisible2 = true">查看详情</p>
          </div>
        </div>
        <div class="progress" :style="{'height': height}">
          <div v-if="isCurrentHolderName && rowData.status < 2" class="panel">
            <div class="point-content">
              <div class="point"></div>
              <div class="acitve bottom-line"></div>
            </div>
            <div class="content" style="margin-top: 0">
              <p class="title">操作处理中</p>
              <el-form ref="ruleForm" class="form" :model="form" :rules="rules">
                <el-form-item prop="description" label="内容">
                  <el-input v-model="form.description" placeholder="请选择内容" />
                </el-form-item>
                <el-form-item prop="permission" label="范围">
                  <el-select v-model="form.permission" style="width: 100%;" placeholder="请选择范围">
                    <el-option
                      v-for="option in config.workBillRemarkPermissionOptions"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <p class="label">上传附件</p>
                <div class="form-item">
                  <el-upload
                    class="upload-demo"
                    :action="`/backEnd/api/${config.supplyCode}/File/uploadFile?source=5`"
                    multiple
                    :limit="3"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :on-progress="handleProgress"
                    :before-upload="handleUpload"
                    :show-file-list="false"
                    :file-list="fileList"
                  >
                    <el-button class="btn">
                      <svg-icon
                        className="icon"
                        iconClass="upload2"
                      />选择文件上传</el-button
                    >
                  </el-upload>
                  <div class="file-list">
                    <div v-for="(file, $key) in fileList" :key="$key" class="file-item">
                      <template v-if="file.percent !== 100">
                        <el-progress
                          type="circle"
                          :stroke-width="4"
                          :percentage="file.percent"
                          :width="40"
                        ></el-progress>
                      </template>
                      <template v-else>
                        <div class="file-link">
                          <svg-icon className="icon2" iconClass="file-link" />
                        </div>
                      </template>
                      <div class="file-des">
                        <p class="file-name">{{ file.name }}</p>
                        <p class="file-size">{{ file.size | formatSize }} mb</p>
                      </div>
                      <div class="opt">
                        <i class="el-icon-delete" @click="deleteFile($key)" />
                      </div>
                    </div>
                  </div>
                </div>
              </el-form>
              <div class="footer">
                <el-button class="close" @click="closeWorkBill('ruleForm')">关闭</el-button>
                <el-button class="update-btn" @click="uploadBtn('ruleForm')">
                  <i class="el-icon-refresh" /> 更新
                </el-button>
                <el-button class="zhuanjiao" @click="zhuanjiao('ruleForm')">
                  <svg-icon iconClass="zhuanjiao" className="icon" />转交</el-button
                >
                <el-button class="success" @click="passWorkBill('ruleForm')">通过</el-button>
              </div>
            </div>
          </div>
          <div v-for="(item, $index) in remarkList" :key="$index" class="panel">
            <div class="point-content">
              <div
                :class="{
                  'acitve':
                    rowData.status < 2 ?
                      isCurrentHolderName &&
                      $index === 0 :
                      $index === 1
                  ,
                  'top-line':
                    rowData.status < 2 &&
                    isCurrentHolderName ||
                    $index > 0}"></div>
              <div class="point"></div>
              <div
                :class="{
                  'acitve': rowData.status < 2 ?
                    !isCurrentHolderName &&
                    $index === 0 &&
                    $index < remarkList.length - 1
                    : $index === 0 &&
                      $index < remarkList.length - 1,
                  'bottom-line': $index < remarkList.length - 1
                }"></div>
            </div>
            <div class="content" :style="{'margin-top': $index === 0 && !isCurrentHolderName ? '0' : '10px'}">
              <p class="title">{{ item.title }}</p>
              <p class="des">
                <span>{{ item.createdName }}</span>
                <span class="date-time">处理时间：{{ item.createdOn ? item.createdOn.slice(0, item.createdOn.length - 3) : '' }}</span>
              </p>
              <p class="des">
                <span>描述：</span>
                <span class="des-value">{{ item.description }}</span>
              </p>
              <p v-if="item.systemFileIds" class="des downLoad" @click="downLoad(item.systemFileIds, item.id)">
                <svg-icon className="icon" iconClass="file-link" />
                <span class="link">
                  附件下载
                  <span class="file-qun">{{ item.systemFileIds | formatFile }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <FileDownloadDialog
          :dialogVisible="dialogVisible"
          :workOrderRemarkId="workOrderRemarkId"
          @cancel="dialogVisible = false"
        ></FileDownloadDialog>
        <CustomersDialog
          :dialogVisible="dialogVisible1"
          @cancel="dialogVisible1 = false"
          @ok="ok"
        ></CustomersDialog>
        <OtherDataDialog
          :tableData="jsonData"
          :dialogVisible="dialogVisible2"
          @cancel="dialogVisible2 = false"
          @ok="dialogVisible2 = false">
        </OtherDataDialog>
      </div>
    </Drawer>
  </div>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import FileDownloadDialog from './FileDownloadDialog.vue'
import CustomersDialog from './CustomersDialog.vue'
import OtherDataDialog from './OtherDataDialog.vue'
import { mapGetters } from 'vuex'
import config from '@/utils/config'
import { workOrderDetail, addWorkOrderRemark, closeWorkOrder, passWorkOrder, transitWorkOrder } from '@/api/system'
import dayjs from 'dayjs'
export default {
  components: { Drawer, FileDownloadDialog, CustomersDialog, OtherDataDialog },
  filters: {
    formatterStatus(status) {
      return config.workBillStatusOptions.find((a) => a.value === status)?.label
    },
    formatSize: function(num) {
      return (num / 1024 / 1024).toFixed(2)
    },
    formatFile(fileIds) {
      return fileIds.split(',').length
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      height: 0,
      config,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      form: {
        description: '',
        permission: 0,
        fileIds: []
      },
      rules: {
        permission: [{ required: true, message: '请选择范围', trigger: 'change' }],
        description: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      workOrderRemarkId: '',
      loading: false,
      fileList: [],
      remarkList: [],
      jsonData: null
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    isCurrentHolderName() {
      return this.rowData.currentHolderName === this.userInfo.userName
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.loading = true
        this.dialogVisible = false
        this.dialogVisible1 = false
        this.jsonData = null
        this.getDetail()
      }
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    },
    downLoad(fileIds, workOrderRemarkId) {
      // if (fileIds.split(',').length > 1) {
       this.dialogVisible = true
       this.workOrderRemarkId = workOrderRemarkId
      // }
    },
    zhuanjiao(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.dialogVisible1 = true
        }
      })
    },
    passWorkBill(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            const res = await passWorkOrder({
              ...this.form,
              workOrderOrderId: this.rowData.id
            })
            if (res.success) {
              this.$message.success('通过成功')
              this.close()
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    needStyle(status) {
      let colorObj
      switch (status) {
        case 0:
          colorObj = {
            color: 'rgba(255, 116, 68, 1)',
            background: 'rgba(255, 116, 68, .1)'
          }
          break
        case 1:
          colorObj = {
            color: 'rgba(35, 149, 243, 1)',
            background: 'rgba(35, 149, 243, .1)'
          }
          break
        case 2:
          colorObj = {
            color: 'rgba(53, 186, 131, 1)',
            background: 'rgba(53, 186, 131, .1)'
          }
          break
        case 3:
          colorObj = {
            color: 'rgba(153, 153, 153, 1)',
            background: 'rgba(153, 153, 153, .1)'
          }
          break
        case 4:
          colorObj = {
            color: 'rgba(254, 69, 61, 1)',
            background: 'rgba(254, 69, 61, .1)'
          }
          break
      }
      return colorObj
    },
    levelStyle(level) {
      let colorObj
      switch (level) {
        case 0:
          colorObj = {
            color: '#666666'
          }
          break
        case 1:
          colorObj = {
            color: '#FEAA4F'
          }
          break
        case 2:
          colorObj = {
            color: '#FE453D'
          }
          break
      }
      return colorObj
    },
    async getDetail() {
      try {
        const res = await workOrderDetail({ id: this.rowData.id })
        if (res.success) {
          this.loading = false
          const newArr = res.data.remarkItems.sort((a, b) => {
            return dayjs(b.createdOn).isAfter(dayjs(a.createdOn)) ? 1 : -1
          })
          this.remarkList = [
            ...newArr
          ]
          if (res.data.jsonValue) {
            const data = JSON.parse(res.data.jsonValue)
            const fileds = data.labels.map(a => {
              return {
                filed: a.value,
                label: a.label,
                width: '100'
              }
            })
            this.jsonData = {
              fileds,
              type: data.type,
              columns: data.data,
              total: data.data.length
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleUpload(file) {
      this.fileList = [...this.fileList, file]
    },
    handleSuccess(res) {
      if (res.success) {
        this.form.fileIds = [...this.form.fileIds, res.data.fileId]
      }
    },
    handleError(error) {
      this.$message.error(error)
    },
    handleProgress(file) {
      const file1 = this.fileList[this.fileList.length - 1]
      file1.percent = file.percent
      this.$set(this.fileList, this.fileList.length - 1, file1)
    },
    deleteFile(index) {
      this.fileList.splice(index, 1)
      this.form.fileIds.splice(index, 1)
    },
    uploadBtn(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            const res = await addWorkOrderRemark({
              ...this.form,
              workOrderOrderId: this.rowData.id
            })
            if (res.success) {
              this.$message.success('更新成功')
              this.fileList = []
              this.form = {
                description: '',
                permission: 0,
                fileIds: []
              }
              this.getDetail()
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    closeWorkBill(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            const res = await closeWorkOrder({
              ...this.form,
              workOrderOrderId: this.rowData.id
            })
            if (res.success) {
              this.$message.success('关闭成功')
              this.close()
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async ok(userObj) {
      try {
        const res = await transitWorkOrder({
              ...this.form,
              workOrderOrderId: this.rowData.id,
              userId: userObj.userId
        })
        if (res.success) {
          this.$message.success('转交成功')
          this.close()
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  .header {
    width: 600px;
    height: 60px;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .left {
      flex: 1;
      display: flex;
      text-indent: 20px;
      align-items: center;
    }
    .title {
      height: 60px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 60px;
    }
    .workbill-no {
      width: auto;
      height: 32px;
      background: #f7f7f7;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      line-height: 32px;
      text-align: center;
      padding-right: 20px;
      margin-left: 20px;
    }
    .icon {
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .bill-top {
    padding: 20px;
    width: calc(100% - 40px);
    height: 135px;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 50%;
      height: 19px;
      display: flex;
      position: relative;
    }
    .label {
      width: 70px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 19px;
    }
    .value {
      width: calc(100% - 75px);
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
      margin-left: 5px;
    }
    .status {
      padding: 6px;
      width: auto;
      position: absolute;
      left: 70px;
      top: -6px;
    }
    .status-add {
      color: #ff7444;
      background: rgba(255, 116, 68, 0.1);
    }
    .status-processing {
      color: #2395f3;
      background: rgba(35, 149, 243, 0.1);
    }
    .status-end {
      color: #35ba83;
      background: rgba(53, 186, 131, 0.1);
    }
    .status-closed {
      color: #999999;
      background: rgba(153, 153, 153, 0.1);
    }
    .level1 {
      color: #feaa4f;
    }
    .level2 {
      color: #fe453d;
    }
    .normal {
      color: #666666;
    }
  }
  .form {
    margin-top: 25px;
  }
  .data {
    width: calc(100% - 40px);
    height: 300px
  }
  .progress {
    width: calc(100% - 40px);
    min-height: calc(100% - 266px);
    margin: 10px 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .panel {
    width: 100%;
    height: auto;
    display: flex;
    &:first-child .point-content .point {
      width: 18px;
      height: 18px;
      background: #ffffff;
      opacity: 1;
      border-radius: 50%;
      position: relative;
      &::after {
        content: " ";
        width: 10px;
        height: 10px;
        background: #35ba83;
        position: absolute;
        border-radius: 50%;
        top: 4px;
        left: 4px;
      }
    }
    .acitve {
      border: 1px dashed #35ba83 !important;
    }
    .point-content {
      width: 20px;
      height: auto;
      display: flex;
      align-items: center;
      position: relative;
      .point {
        width: 18px;
        height: 18px;
        background: #ffffff;
        opacity: 1;
        border: 1px solid #cdcdcd;
        border-radius: 50%;
        position: relative;
        &::after {
          content: " ";
          width: 10px;
          height: 10px;
          background: #cdcdcd;
          position: absolute;
          border-radius: 50%;
          top: 4px;
          left: 4px;
        }
        &::before {
          content: " ";
        }
      }
      .bottom-line {
        width: 0px;
        height: calc(50% - 10px);
        opacity: 1;
        border: 1px dashed #cdcdcd;
        position: absolute;
        top: 50%;
        margin-top: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
      .top-line {
        width: 0px;
        height: calc(50% - 10px);
        opacity: 1;
        border: 1px dashed #cdcdcd;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .content {
      margin-top: 10px;
      width: 490px;
      background: #f7f7f7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      padding: 18px 20px;
      margin-left: 10px;
      .des {
        margin-bottom: 10px;
      }
      .des:last-child {
        margin-bottom: 0;
      }
    }
    .title {
      width: auto;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
    .des {
      width: auto;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      margin-top: 10px;
    }
    .date-time {
      margin-left: 10px;
    }
    .des-value {
      width: auto;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
    .icon {
      font-size: 14px;
    }
    .link {
      width: 56px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      line-height: 24px;
      margin-left: 5px;
    }
    .downLoad {
      cursor: pointer;
    }
    &:deep(.el-form-item) {
      margin-bottom: 20px;
    }
    &:deep(.el-form-item__label) {
      line-height: 22px;
    }
    .label {
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    .required {
      color: rgba(255, 116, 68, 1);
    }
    .form-item {
      margin-top: 5px;
      width: 100%;
    }
    .btn {
      width: 140px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-weight: 400;
      color: #2395f3;
      padding: 10px 16px;
    }
    .file-list {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .file-item {
      width: 150px;
      height: 40px;
      display: flex;
      & + .file-item {
        margin-left: 15px;
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 10px;
      }
    }
    .file-des {
      margin: 5px;
      width: 80px;
      height: 40px;
    }
    .file-name {
      width: 100%;
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-size {
      width: 100%;
      height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }
    .opt {
      width: 20px;
      height: 40px;
      font-size: 20px;
      line-height: 40px;
      color: #999999;
      cursor: pointer;
    }
    .file-link {
      width: 40px;
      height: 40px;
    }
    .icon2 {
      font-size: 32px;
      margin: 4px;
    }
  }
  .footer {
    width: 100%;
    margin-top: 20px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    & .el-button {
      padding: 0;
      /deep/ span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .update-btn {
      width: 80px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      color: #ffffff;
    }
    .close {
      width: 80px;
      height: 40px;
      background: #fe453d;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      color: #ffffff;
    }
    .zhuanjiao {
      width: 80px;
      height: 40px;
      background: #35ba83;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      color: #ffffff;
      .icon {
        font-size: 20px;
      }
    }
    .success {
      width: 80px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      color: #ffffff;
    }
  }
  .file-qun {
    display: inline-block;
    background: #2395F3;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 16px;
    padding: 0 4px;
    border-radius: 50%;
  }
}
</style>
