var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "1020px",
        "show-close": true,
        title: "创建工单",
        "append-to-body": "",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "基础类目", prop: "workOrderTypeId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择基础类目" },
                              on: {
                                change: () => {
                                  ;(_vm.form.workOrderItemId = ""),
                                    _vm.handleChange()
                                },
                              },
                              model: {
                                value: _vm.form.workOrderTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "workOrderTypeId", $$v)
                                },
                                expression: "form.workOrderTypeId",
                              },
                            },
                            _vm._l(_vm.workBillTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "子类目", prop: "workOrderItemId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择子类目" },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.form.workOrderItemId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "workOrderItemId", $$v)
                                },
                                expression: "form.workOrderItemId",
                              },
                            },
                            _vm._l(_vm.workBillItemOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "参考单号",
                            prop: "referenceNumber",
                            error: _vm.errorMessage,
                            required: _vm.isRequired,
                          },
                        },
                        [
                          _c("el-input", {
                            on: { blur: _vm.blur },
                            model: {
                              value: _vm.form.referenceNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "referenceNumber", $$v)
                              },
                              expression: "form.referenceNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优先级", prop: "priorityLevel" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择优先级" },
                              model: {
                                value: _vm.form.priorityLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "priorityLevel", $$v)
                                },
                                expression: "form.priorityLevel",
                              },
                            },
                            _vm._l(
                              _vm.config.priorityLevelOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: { rows: 1, placeholder: "请输入描述" },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                _vm._l(_vm.otherParmas, function (param, $index) {
                  return _c(
                    "el-col",
                    { key: $index, attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: param.label, prop: param.key } },
                        [
                          param.type === 0
                            ? [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请输入内容" },
                                  on: {
                                    change: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          param.type === 8
                            ? [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "password",
                                    placeholder: "请输入内容",
                                  },
                                  on: {
                                    change: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          param.type === 2
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请选择内容" },
                                    on: {
                                      change: (value) => {
                                        _vm.changeItem(value, $index)
                                      },
                                    },
                                    model: {
                                      value: param.value,
                                      callback: function ($$v) {
                                        _vm.$set(param, "value", $$v)
                                      },
                                      expression: "param.value",
                                    },
                                  },
                                  _vm._l(param.option, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            : _vm._e(),
                          param.type === 1
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      multiple: "",
                                      placeholder: "请选择内容",
                                    },
                                    on: {
                                      change: (value) => {
                                        _vm.changeItem(value, $index)
                                      },
                                    },
                                    model: {
                                      value: param.value,
                                      callback: function ($$v) {
                                        _vm.$set(param, "value", $$v)
                                      },
                                      expression: "param.value",
                                    },
                                  },
                                  _vm._l(param.option, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            : _vm._e(),
                          param.type === 5
                            ? [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    align: "center",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "选择日期",
                                  },
                                  on: {
                                    change: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          param.type === 6
                            ? [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    align: "center",
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH-mm-ss",
                                    placeholder: "选择日期时间",
                                  },
                                  on: {
                                    change: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          param.type === 7
                            ? [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    align: "center",
                                    type: "daterange",
                                    "range-separator": "-",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    "unlink-panels": "",
                                    "picker-options": _vm.pickerOptions,
                                  },
                                  on: {
                                    change: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          param.type === 4
                            ? [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "controls-position": "right",
                                    min: param.option[0].min,
                                    max: param.option[0].max,
                                    precision: param.option[0].precision,
                                  },
                                  on: {
                                    change: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          param.type === 3
                            ? [
                                _c("number-range", {
                                  class: { "is-error": param.error },
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    min: param.option[0].min,
                                    max: param.option[0].max,
                                    precision: param.option[0].precision,
                                  },
                                  on: {
                                    input: (value) => {
                                      _vm.changeItem(value, $index)
                                    },
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function ($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c("el-row", [
                _c("p", { staticClass: "lable" }, [_vm._v("上传附件")]),
                _c(
                  "div",
                  { staticClass: "upload" },
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: `/backEnd/api/${_vm.config.supplyCode}/File/uploadFile?source=5`,
                          "on-success": _vm.handleSuccess,
                          "on-error": _vm.handleError,
                          "on-progress": _vm.handleProgress,
                          "before-upload": _vm.handleUpload,
                          limit: 3,
                          "file-list": _vm.fileList,
                          "show-file-list": false,
                          "on-exceed": _vm.onExceed,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { staticClass: "load", attrs: { size: "small" } },
                          [
                            _c("i", {
                              staticClass: "el-icon-upload2",
                              staticStyle: {
                                "font-size": "24px",
                                color: "#b8c3de",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.fileList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "fileList" },
                          _vm._l(_vm.fileList, function (item, $index) {
                            return _c(
                              "div",
                              { key: $index, staticClass: "file" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    0
                                      ? _c(
                                          "div",
                                          { staticClass: "file-icon" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/seting/file.png"),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "file-progress" },
                                          [
                                            _c("el-progress", {
                                              attrs: {
                                                type: "circle",
                                                "stroke-width": 4,
                                                percentage: item.percent,
                                                width: 40,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                    _c("div", { staticClass: "file-des" }, [
                                      _c("p", { staticClass: "file-name" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _c("p", { staticClass: "file-size" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatSize")(item.size)
                                          ) + " mb"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "opt" }, [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFile($index)
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("ruleForm")
                },
              },
            },
            [_vm._v("提交")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }