<template>
  <div class="view-contanier">
    <div class="opt">
      <div class="search">
        <el-input
          v-model="searchVal"
          placeholder="请输入工单号"
          class="input-search"
          @keydown.enter.native="search"
        ></el-input>
        <div class="btn-search" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <div
        class="btn"
        @click="add">
        <svg-icon className="icon" iconClass="add" />新增
      </div>
      <BaseFilter
        :width="488"
        :isActive="isActive"
        @sendFilter="sendFilter"
        @reset="reset"
      >
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="84px">
            <el-form-item label="客户">
              <el-select
                v-model="filterList.companyIds"
                style="width: 320px;"
                placeholder="请选择类型"
                multiple
              >
                <el-option
                  v-for="item in companyDrapdown"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="大类">
              <el-select
                v-model="filterList.workOrderTypeId"
                style="width: 320px;"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in workOrderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="细类">
              <el-select
                v-model="filterList.workOrderItemId"
                style="width: 320px;"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in workOrderItemOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工单优先级">
              <el-select
                v-model="filterList.priorityLevel"
                style="width: 320px;"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in config.priorityLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工单状态">
              <el-select
                v-model="filterList.status"
                style="width: 320px;"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in config.workBillStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
      >
        <template slot="status" slot-scope="row">
          <span class="tag" :style="needStyle(row.scope.status)">{{
            row.scope.status | formatterStatus
          }}</span>
        </template>
        <template slot="priorityLevelName" slot-scope="row">
          <span :class="[`priority${row.scope.priorityLevel}`]">{{ row.scope.priorityLevelName }}</span>
        </template>
        <template slot="opt" slot-scope="row">
          <el-button
            v-permissson="'detail'"
            type="text"
            @click="viewDetail(row.scope)"
          >查看详情</el-button
          >
        </template>
      </BaseTable>
    </div>
    <DetailDrawer
      :drawer="drawer"
      :rowData="editData"
      :isOperators="isOperators"
      @close="close"
    ></DetailDrawer>
    <WorkOrderDiaolg
      :dialogVisible="dialogVisible"
      @ok="ok"
      @cancel="dialogVisible = false">
    </WorkOrderDiaolg>
  </div>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import DetailDrawer from './components/DetailDrawer.vue'
import WorkOrderDiaolg from './components/WorkOrderDiaolg.vue'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
import { getWorkOrderTypeList, getWorkOrderItemList } from '@/api/system'
import { getWorkOrderList } from '@/api/system'
import { myMixins } from '@/mixins'
export default {
  name: 'WorkList',
  components: { BaseTable, BaseFilter, DetailDrawer, WorkOrderDiaolg },
  filters: {
    formatterStatus(status) {
      return config.workBillStatusOptions.find((a) => a.value === status).label
    }
  },
  mixins: [myMixins],
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: '200'
          },
          {
            filed: 'workOrderNo',
            label: '工单号',
            width: '220',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'workOrderTypeName',
            label: '问题大类',
            width: '120'
          },
          {
            filed: 'workOrderItemName',
            label: '问题细类',
            width: '120'
          },
          {
            filed: 'description',
            label: '问题描述',
            width: '120'
          },
          {
            filed: 'priorityLevelName',
            label: '优先级',
            width: '120',
            isCustom: true
          },
          {
            filed: 'referenceNumber',
            label: '参考号',
            width: '180'
          },
          {
            filed: 'status',
            label: '工单状态',
            width: '100',
            isCustom: true
          },
          {
            filed: 'createdOn',
            label: '提交时间',
            width: '170',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'createdName',
            label: '提交人',
            width: '180'
          },
          {
            filed: 'currentHolderName',
            label: '当前执行人',
            width: '180'
          },
          {
            filed: 'updatedOn',
            label: '更新时间',
            width: '170',
            formatter: (row) =>
              row.updatedOn ? row.updatedOn.slice(0, row.updatedOn.length - 3) : ''
          },
          {
            filed: 'opt',
            label: '操作',
            width: '150',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      config,
      height: 0,
      editData: {},
      isActive: false,
      drawer: false,
      filterList: {
        companyIds: '',
        workOrderTypeId: '',
        workOrderItemId: '',
        priorityLevel: '',
        status: ''
      },
      workOrderOptions: [],
      workOrderItem: [],
      isOperators: true,
      dialogVisible: false,
      searchVal: ''
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    workOrderItemOptions() {
      return this.workOrderItem.filter(a => {
        return a.workOrderTypeid === this.filterList.workOrderTypeId
      }).map(item => {
        return {
              value: item.id,
              label: item.name
        }
      })
    }
  },
  watch: {
    '$store.state.connect.connect': {
      handler: function(query) {
        if (query.menuCode === this.config.menuCode.GD) {
         this.searchVal = query.workOrderNo
         this.search()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getList(this.pagerConfig)
    this.getWorkOrder()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.formatterFilter()
          }
        }
        getWorkOrderList(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.total = res.data.total
            this.tableData.columns = res.data.list
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    async getWorkOrder() {
      try {
        const res = await getWorkOrderTypeList({
          pageIndex: 1,
          pageSize: 1000
        })
        if (res.success) {
          const arr = res.data.map((item) => {
            return {
              value: item.id,
              label: item.name
            }
          })
          this.workOrderOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
        const res1 = await getWorkOrderItemList({
          pageIndex: 1,
          pageSize: 1000
        })
        if (res1.success) {
          this.workOrderItem = res1.data
        } else {
          this.$message.error(res1.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    sendFilter() {
      if (
        this.filterList.companyIds !== '' ||
        this.filterList.workOrderTypeId !== '' ||
        this.filterList.workOrderItemId !== '' ||
        this.filterList.priorityLevel !== '' ||
        this.filterList.status
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    search() {
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList(params)
    },
    reset() {
      this.filterList = {
        companyIds: '',
        workOrderTypeId: '',
        workOrderItemId: '',
        priorityLevel: '',
        status: ''
      }
      this.isActive = false
    },
    viewDetail(row) {
      this.drawer = true
      this.editData = row
    },
    needStyle(status) {
      let colorObj
      switch (status) {
        case 0:
          colorObj = {
            color: 'rgba(255, 116, 68, 1)',
            background: 'rgba(255, 116, 68, .1)'
          }
          break
        case 1:
          colorObj = {
            color: 'rgba(35, 149, 243, 1)',
            background: 'rgba(35, 149, 243, .1)'
          }
          break
        case 2:
          colorObj = {
            color: 'rgba(53, 186, 131, 1)',
            background: 'rgba(53, 186, 131, .1)'
          }
          break
        case 3:
          colorObj = {
            color: 'rgba(153, 153, 153, 1)',
            background: 'rgba(153, 153, 153, .1)'
          }
          break
        case 4:
          colorObj = {
            color: 'rgba(254, 69, 61, 1)',
            background: 'rgba(254, 69, 61, .1)'
          }
          break
      }
      return colorObj
    },
    close() {
      this.drawer = false
      this.getList(this.pagerConfig)
    },
    formatterFilter() {
      return {
        companyIds:
          this.filterList.companyIds !== ''
            ? this.filterList.companyIds
            : undefined,
        workOrderTypeId:
          this.filterList.workOrderTypeId !== ''
            ? this.filterList.workOrderTypeId
            : undefined,
        workOrderItemId:
          this.filterList.workOrderItemId !== ''
            ? this.filterList.workOrderItemId
            : undefined,
        priorityLevel:
          this.filterList.priorityLevel !== ''
            ? this.filterList.priorityLevel
            : undefined,
        workOrderNo: this.searchVal !== ''
            ? this.searchVal
            : undefined,
        status:
          this.filterList.status !== '' ? this.filterList.status : undefined
      }
    },
    add() {
      this.dialogVisible = true
    },
    ok() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .search {
      position: relative;
      display: flex;
      width: 200px;
      margin-right: 8px;
      .input-search {
        width: 166px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  .btn {
    width: 80px;
    height: 28px;
    background: #2395f3;
    border-radius: 4px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .tag {
      padding: 6px 11px;
    }
    .priority1 {
      color: #FE453D;
    }
    .priority2 {
      color: #FEAA4F;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
  .filter {
    width: 438px;
  }
}
.select {
  width: 100%;
  &:deep(.el-select) {
    width: 100%;
  }
}
.table-opt:deep(.el-button) {
  padding: 0;
}
.status {
  width: 28px;
  height: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #2395f3;
  line-height: 20px;
  margin-left: 5px;
  margin-right: 31px;
}
.icon {
  font-size: 24px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
