var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible,
          expression: "dialogVisible",
        },
      ],
      staticClass: "oms-dialog-wrapper",
    },
    [
      _c("div", { staticClass: "oms-dialog" }, [
        _c("div", { staticClass: "oms-dialog-header" }, [
          _c("p", { staticClass: "title" }, [_vm._v("附件下载")]),
          _c("i", {
            staticClass: "el-icon-close close",
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          { staticClass: "oms-dialog-body" },
          [
            _c("BaseTable", {
              attrs: {
                height: 160,
                tableData: _vm.tableData,
                isPager: false,
                headerCellStyle: _vm.headerCellStyle,
                loading: _vm.loading,
              },
              scopedSlots: _vm._u([
                {
                  key: "opt",
                  fn: function (row) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.download(row.scope)
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            attrs: { className: "icon", iconClass: "download" },
                          }),
                          _vm._v("下载 "),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }