<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="800px"
    title="附加数据"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    append-to-body
  >
    <BaseTable height="440" :tableData="tableData" :isPager="false">
    </BaseTable>
    <span slot="footer" class="footers">
      <el-button class="ok" @click="ok">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: function() {
        return {
          fileds: [],
          columns: [],
          total: 0
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async ok() {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="less" scoped>
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #f2f7ff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dbe4f2;
    color: #7c8db5;
    margin-right: 6px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
}
.send-type {
  display: flex;
  .filed {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    label {
      width: 73px;
      text-align: right;
      display: inline-block;
    }
  }
  .des {
    width: 162px;
  }
}
.send-list {
  width: 100%;
  max-height: 240px;
  background: #ffffff;
  position: relative;
  .panel {
    width: 100%;
    height: 80px;
    border-radius: 4px;
    display: flex;
  }
  .select {
    background: #f8fbff;
  }
  img {
    margin: 10px 15px 10 5px;
  }
  .des1 {
    width: 148px;
    font-size: 12px;
    line-height: 16px;
    padding: 22px 0;
    span {
      margin-left: 10px;
    }
  }
  .des2 {
    width: 170px;
    font-size: 12px;
    line-height: 16px;
    padding: 22px 0;
    label {
      width: 73px;
    }
    span {
      margin-left: 10px;
    }
  }
  .checkbox {
    position: absolute;
    right: 10px;
    padding-top: 32px;
  }
}
.loading {
  height: 35px;
}
.wait-upload {
  font-size: 12px;
  font-weight: 400;
  color: #b8c3de;
  line-height: 24px;
}
.icon {
  font-size: 16px;
}
</style>
