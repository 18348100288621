<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="1020px"
    :show-close="true"
    title="创建工单"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="10vh"
  >
    <div class="box">
      <el-form
        ref="ruleForm"
        class="form"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="基础类目" prop="workOrderTypeId">
              <el-select
                v-model="form.workOrderTypeId"
                style="width: 100%"
                placeholder="请选择基础类目"
                @change="() => { form.workOrderItemId = '', handleChange() }"
              >
                <el-option
                  v-for="item in workBillTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="子类目" prop="workOrderItemId">
              <el-select
                v-model="form.workOrderItemId"
                style="width: 100%"
                placeholder="请选择子类目"
                @change="handleChange"
              >
                <el-option
                  v-for="item in workBillItemOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="参考单号" prop="referenceNumber" :error="errorMessage" :required="isRequired">
              <el-input v-model="form.referenceNumber" @blur="blur"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="优先级" prop="priorityLevel">
              <el-select
                v-model="form.priorityLevel"
                style="width: 100%"
                placeholder="请选择优先级"
              >
                <el-option
                  v-for="item in config.priorityLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="描述" prop="description">
            <el-input
              v-model="form.description"
              :rows="1"
              placeholder="请输入描述"
            >
            </el-input>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-col v-for="(param, $index) in otherParmas" :key="$index" :span="8">
            <el-form-item :label="param.label" :prop="param.key">
              <template v-if="param.type === 0">
                <el-input
                  v-model="param.value"
                  style="width: 100%"
                  placeholder="请输入内容"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                />
              </template>
              <template v-if="param.type === 8">
                <el-input
                  v-model="param.value"
                  type="password"
                  style="width: 100%"
                  placeholder="请输入内容"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                />
              </template>
              <template v-if="param.type === 2">
                <el-select
                  v-model="param.value"
                  style="width: 100%"
                  placeholder="请选择内容"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                >
                  <el-option
                    v-for="item in param.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-if="param.type === 1">
                <el-select
                  v-model="param.value"
                  multiple
                  style="width: 100%"
                  placeholder="请选择内容"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                >
                  <el-option
                    v-for="item in param.option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-if="param.type === 5">
                <el-date-picker
                  v-model="param.value"
                  align="center"
                  value-format="yyyy-MM-dd"
                  type="date"
                  style="width: 100%"
                  placeholder="选择日期"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                >
                </el-date-picker>
              </template>
              <template v-if="param.type === 6">
                <el-date-picker
                  v-model="param.value"
                  align="center"
                  style="width: 100%"
                  type="datetime"
                  value-format="yyyy-MM-dd HH-mm-ss"
                  placeholder="选择日期时间"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                >
                </el-date-picker>
              </template>
              <template v-if="param.type === 7">
                <el-date-picker
                  v-model="param.value"
                  align="center"
                  style="width: 100%"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  unlink-panels
                  :picker-options="pickerOptions"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                >
                </el-date-picker>
              </template>
              <template v-if="param.type === 4">
                <el-input-number
                  v-model="param.value"
                  style="width: 100%"
                  controls-position="right"
                  :min="param.option[0].min"
                  :max="param.option[0].max"
                  :precision="param.option[0].precision"
                  @change="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                ></el-input-number>
              </template>
              <template v-if="param.type === 3">
                <number-range
                  v-model="param.value"
                  style="width: 100%"
                  :min="param.option[0].min"
                  :max="param.option[0].max"
                  :precision="param.option[0].precision"
                  :class="{ 'is-error': param.error }"
                  @input="
                    (value) => {
                      changeItem(value, $index);
                    }
                  "
                >
                </number-range>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <p class="lable">上传附件</p>
          <div class="upload">
            <el-upload
              :action="`/backEnd/api/${config.supplyCode}/File/uploadFile?source=5`"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-progress="handleProgress"
              :before-upload="handleUpload"
              :limit="3"
              :file-list="fileList"
              :show-file-list="false"
              :on-exceed="onExceed"
            >
              <el-button size="small" class="load">
                <i
                  class="el-icon-upload2"
                  style="font-size: 24px; color: #b8c3de"
                />
              </el-button>
            </el-upload>
            <div v-if="fileList.length > 0" class="fileList">
              <div v-for="(item, $index) in fileList" :key="$index" class="file">
                <div style="display: flex;">
                  <div v-if="0" class="file-icon">
                    <img src="@/assets/images/seting/file.png" />
                  </div>
                  <div v-else class="file-progress">
                    <el-progress type="circle" :stroke-width="4" :percentage="item.percent" :width="40"></el-progress>
                  </div>
                  <div class="file-des">
                    <p class="file-name">{{ item.name }}</p>
                    <p class="file-size">{{ item.size | formatSize }} mb</p>
                  </div>
                </div>
                <div class="opt">
                  <i class="el-icon-delete" @click="deleteFile($index)" />
                </div>
              </div>
            </div>
          </div>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('ruleForm')">提交</div>
    </span>
  </el-dialog>
</template>
<script>
import config from '@/utils/config'
import {
  getWorkOrderTypeList,
  getWorkOrderItemList,
  addWorkOrder
} from '@/api/system'
export default {
  filters: {
    formatSize: function(num) {
      return (num / 1024 / 1024).toFixed(2)
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      config,
      form: {
        workOrderTypeId: '',
        workOrderItemId: '',
        description: '',
        referenceNumber: '',
        priorityLevel: 0,
        fileIds: []
      },
      rules: {
        workOrderTypeId: [
          { required: true, message: '请选择基础类目', trigger: 'change' }
        ],
        workOrderItemId: [
          { required: true, message: '请选择子类目', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ]
      },
      fileList: [],
      workBillTypeOptions: [],
      workBillItem: [],
      errorMessage: '',
      otherParmas: []
    }
  },
  computed: {
    workBillItemOptions: function() {
      return this.workBillItem
        .filter((a) => a.workOrderTypeid === this.form.workOrderTypeId)
        .map((item) => {
          return {
            value: item.id,
            code: item.code,
            label: `${item.code}-${item.name}`
          }
        })
    },
    isRequired() {
      const flag = !!this.workBillItem
        .find((a) => a.id === this.form.workOrderItemId)?.jsonValue
      console.log('flag', flag)
      return !!this.workBillItem
        .find((a) => a.id === this.form.workOrderItemId)?.jsonValue
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleChange() {
       const jsonValue = this.workBillItem
        .find((a) => a.id === this.form.workOrderItemId)?.jsonValue
        const params = JSON.parse(jsonValue || '[]').map(a => {
          return {
            ...a,
            value: ''
          }
        })
        if (params && params.length > 0) {
          this.otherParmas = params
        } else {
          this.otherParmas = []
        }
    },
    async getList() {
      try {
        const res = await getWorkOrderTypeList()
        const res1 = await getWorkOrderItemList()
        const workBillType = res.data.map((item) => {
          return {
            value: item.id,
            code: item.code,
            label: `${item.code}-${item.name}`
          }
        })
        this.workBillTypeOptions = workBillType
        this.workBillItem = res1.data
      } catch (error) {
        console.error(error)
      }
    },
    blur() {
      if (this.isRequired && !this.form.referenceNumber) {
        this.errorMessage = '请填写参考单号'
      }
    },
    handleUpload(file) {
      console.log(file)
      this.fileList = [...this.fileList, file]
    },
    handleSuccess(res) {
      if (res.success) {
        this.form.fileIds = [...this.form.fileIds, res.data.fileId]
      }
    },
    handleError(error) {
      this.$message.error(error)
    },
    handleProgress(file) {
      const file1 = this.fileList[this.fileList.length - 1]
      file1.percent = file.percent
      this.$set(this.fileList, this.fileList.length - 1, file1)
    },
    cancel() {
      this.$refs['ruleForm'].resetFields()
      this.fileList = []
      this.otherParmas = []
      this.errorMessage = ''
      this.$emit('cancel')
    },
    ok(formName) {
      if (this.isRequired && !this.form.referenceNumber) {
        this.errorMessage = '请填写参考单号'
        return
      }
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const otherParmas = []
             this.otherParmas.forEach(a => {
              if (a.value) {
                otherParmas.push({
                  ...a,
                  value: a.value.toString()
                })
              }
            })
            const res = await addWorkOrder({
              workOrderTypeId: this.form.workOrderTypeId || undefined,
              workOrderItemId: this.form.workOrderItemId || undefined,
              description: this.form.description || undefined,
              referenceNumber: this.form.referenceNumber || undefined,
              priorityLevel: this.form.priorityLevel || undefined,
              fileIds:
                this.form.fileIds.length > 0 ? this.form.fileIds : undefined,
              jsonValue: otherParmas.length > 0
               ? JSON.stringify(otherParmas)
                : undefined
            })
            if (res.success) {
              this.$message.success('新增成功')
              this.$refs['ruleForm'].resetFields()
              this.fileList = []
              this.otherParmas = []
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    deleteFile(index) {
      this.fileList.splice(index, 1)
      this.form.fileIds.splice(index, 1)
    },
    onExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    changeItem(value, index) {
      this.otherParmas.splice(index, 1, {
        ... this.otherParmas[index],
        value
      })
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  .form {
    width: 940px;
    margin: 0 auto;
    .lable {
      margin-top: 10px;
    }
    .upload {
      margin-top: 10px;
      display: flex;
    }
    .load {
      width: 60px;
      height: 60px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #dbe4f2;
    }
    .fileList {
      width: 866px;
      margin-left: 10px;
      background: #ffffff;
      display: flex;
      flex-wrap: wrap;
    }
    .file {
      width: 282px;
      height: 60px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      & + .file {
        margin-left: 10px;
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 10px;
      }
    }
    .file-icon {
      margin: 14px 0 14px 8px;
      width: 32px;
    }
    .file-progress {
      width: 40px;
      margin: 10px 0 10px 8px;
    }
    .file-des {
      margin: 10px 10px;
      width: 100px;
      height: 40px;
    }
    .file-name {
      width: 100%;
      height: 19px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-size {
      width: 100%;
      height: 19px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 19px;
    }
    .opt {
      width: 20px;
      height: 60px;
      font-size: 20px;
      line-height: 60px;
      color: #999999;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
&:deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    color: #999999;
    margin-right: 16px;
    box-sizing: border-box;
  }
  .ok {
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
  }
}
</style>
