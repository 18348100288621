var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("el-input", {
                staticClass: "input-search",
                attrs: { placeholder: "请输入工单号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchVal,
                  callback: function ($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal",
                },
              }),
              _c(
                "div",
                { staticClass: "btn-search", on: { click: _vm.search } },
                [_c("i", { staticClass: "el-icon-search" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn", on: { click: _vm.add } },
            [
              _c("svg-icon", {
                attrs: { className: "icon", iconClass: "add" },
              }),
              _vm._v("新增 "),
            ],
            1
          ),
          _c(
            "BaseFilter",
            {
              attrs: { width: 488, isActive: _vm.isActive },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "84px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                placeholder: "请选择类型",
                                multiple: "",
                              },
                              model: {
                                value: _vm.filterList.companyIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "companyIds", $$v)
                                },
                                expression: "filterList.companyIds",
                              },
                            },
                            _vm._l(_vm.companyDrapdown, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "大类" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.filterList.workOrderTypeId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterList,
                                    "workOrderTypeId",
                                    $$v
                                  )
                                },
                                expression: "filterList.workOrderTypeId",
                              },
                            },
                            _vm._l(_vm.workOrderOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "细类" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.filterList.workOrderItemId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterList,
                                    "workOrderItemId",
                                    $$v
                                  )
                                },
                                expression: "filterList.workOrderItemId",
                              },
                            },
                            _vm._l(_vm.workOrderItemOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工单优先级" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.filterList.priorityLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "priorityLevel", $$v)
                                },
                                expression: "filterList.priorityLevel",
                              },
                            },
                            _vm._l(
                              _vm.config.priorityLevelOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "工单状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.filterList.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "status", $$v)
                                },
                                expression: "filterList.status",
                              },
                            },
                            _vm._l(
                              _vm.config.workBillStatusOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: { handlePageChange: _vm.handlePageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "status",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "tag",
                              style: _vm.needStyle(row.scope.status),
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatterStatus")(row.scope.status)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "priorityLevelName",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            { class: [`priority${row.scope.priorityLevel}`] },
                            [_vm._v(_vm._s(row.scope.priorityLevelName))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permissson",
                                  rawName: "v-permissson",
                                  value: "detail",
                                  expression: "'detail'",
                                },
                              ],
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(row.scope)
                                },
                              },
                            },
                            [_vm._v("查看详情")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4128123876
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("DetailDrawer", {
        attrs: {
          drawer: _vm.drawer,
          rowData: _vm.editData,
          isOperators: _vm.isOperators,
        },
        on: { close: _vm.close },
      }),
      _c("WorkOrderDiaolg", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }