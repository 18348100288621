var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible,
          expression: "dialogVisible",
        },
      ],
      staticClass: "oms-dialog-wrapper",
    },
    [
      _c("div", { staticClass: "oms-dialog" }, [
        _c(
          "div",
          { staticClass: "oms-dialog-body" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "form",
                attrs: { model: _vm.form, rules: _vm.rules },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "des", label: "选择转交用户" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.userId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userId", $$v)
                          },
                          expression: "form.userId",
                        },
                      },
                      _vm._l(_vm.userOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "oms-dialog-footer" }, [
          _c(
            "div",
            {
              staticClass: "btn cancel",
              on: {
                click: function ($event) {
                  return _vm.cancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "btn ok",
              on: {
                click: function ($event) {
                  return _vm.ok("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }