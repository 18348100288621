<template>
  <div v-show="dialogVisible" class="oms-dialog-wrapper">
    <div class="oms-dialog">
      <div class="oms-dialog-header">
        <p class="title">附件下载</p>
        <i class="el-icon-close close" @click="close" />
      </div>
      <div class="oms-dialog-body">
        <BaseTable
          :height="160"
          :tableData="tableData"
          :isPager="false"
          :headerCellStyle="headerCellStyle"
          :loading="loading">
          <template slot="opt" slot-scope="row">
            <el-button type="text" @click="download(row.scope)">
              <svg-icon className="icon" iconClass="download" />下载
            </el-button>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { workOrderFile } from '@/api/system'
import config from '@/utils/config'
export default {
    components: { BaseTable },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        workOrderRemarkId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tableData: {
                fileds: [
                {
                    filed: 'fileName',
                    label: '文件名',
                    width: ''
                },
                {
                    filed: 'opt',
                    label: '操作',
                    width: '100px',
                    isCustom: true
                }
                ],
                columns: [],
                total: 0
            },
            loading: false,
            headerCellStyle: {
                height: '40px',
                background: '#F7F7F7',
                color: '#333333',
                fontWeight: '400'
            }
        }
    },
    watch: {
        dialogVisible(val) {
            if (val) {
                this.getList()
            }
        }
    },
    methods: {
        close() {
            this.$emit('cancel')
        },
        async getList() {
            try {
                const res = await workOrderFile({ workOrderRemarkId: this.workOrderRemarkId })
                if (res.success) {
                    this.tableData.columns = res.data
                } else {
                    this.$message.error(res.errorMessage)
                }
            } catch (error) {
                console.log(error)
            }
        },
        download(item) {
            const { guid } = item
            const elink = document.createElement('a')
            elink.style.display = 'none'
            elink.href =
                `/backEnd/api/${config.supplyCode}/File/downLoadFile?guid=` + guid
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
        }
    }
}
</script>
<style lang="less" scoped>
.oms-dialog-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin: 0;
    width: 600px;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .oms-dialog {
        width: 460px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        padding: 20px;
        margin: 0 auto;
    }
    .oms-dialog-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
        .title {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
        }
        .close {
            font-size: 20px;
            color: #999999;
            cursor: pointer;
        }
    }
    .oms-dialog-body {
        width: 100%;
        height: calc(100% - 60px);
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #EEEEEE;
        border-bottom: 0;
        box-sizing: border-box;
        overflow: hidden;
    }
    .icon {
        font-size: 18px;
        vertical-align: sub;
        color: #2395F3;
    }
    &:deep(.el-table--medium .el-table__cell) {
        padding: 0;
    }
}
</style>
