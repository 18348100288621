var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        visible: _vm.dialogVisible,
        width: "800px",
        title: "附加数据",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("BaseTable", {
        attrs: { height: "440", tableData: _vm.tableData, isPager: false },
      }),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { staticClass: "ok", on: { click: _vm.ok } }, [
            _vm._v("确定"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }