var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: { drawer: _vm.drawer, withHeader: false, size: 600 },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "view",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "left" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("工单详情")]),
                  _c("p", { staticClass: "workbill-no" }, [
                    _vm._v("工单编号：" + _vm._s(_vm.rowData.workOrderNo)),
                  ]),
                ]),
                _c("i", {
                  staticClass: "el-icon-close icon",
                  on: { click: _vm.close },
                }),
              ]),
              _c("div", { staticClass: "bill-top" }, [
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("客户：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rowData.companyName)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("问题大类：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rowData.workOrderTypeName)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("问题细类：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rowData.workOrderItemName)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("工单状态：")]),
                  _c(
                    "p",
                    {
                      staticClass: "value status",
                      style: _vm.needStyle(_vm.rowData.status),
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatterStatus")(_vm.rowData.status)
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("优先级：")]),
                  _c(
                    "p",
                    {
                      staticClass: "value",
                      style: _vm.levelStyle(_vm.rowData.priorityLevel),
                    },
                    [_vm._v(" " + _vm._s(_vm.rowData.priorityLevelName) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("提交人：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rowData.createdName)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("参考号码：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rowData.referenceNumber)),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("提交时间：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.rowData.createdOn
                          ? _vm.rowData.createdOn.slice(
                              0,
                              _vm.rowData.createdOn.length - 3
                            )
                          : ""
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("p", { staticClass: "label" }, [_vm._v("问题描述：")]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rowData.description)),
                  ]),
                ]),
                _vm.jsonData
                  ? _c("div", { staticClass: "item" }, [
                      _c("p", { staticClass: "label" }, [_vm._v("附加数据：")]),
                      _c(
                        "p",
                        {
                          staticClass: "value",
                          staticStyle: { color: "#66b1ff", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible2 = true
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "progress", style: { height: _vm.height } },
                [
                  _vm.isCurrentHolderName && _vm.rowData.status < 2
                    ? _c("div", { staticClass: "panel" }, [
                        _c("div", { staticClass: "point-content" }, [
                          _c("div", { staticClass: "point" }),
                          _c("div", { staticClass: "acitve bottom-line" }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "content",
                            staticStyle: { "margin-top": "0" },
                          },
                          [
                            _c("p", { staticClass: "title" }, [
                              _vm._v("操作处理中"),
                            ]),
                            _c(
                              "el-form",
                              {
                                ref: "ruleForm",
                                staticClass: "form",
                                attrs: { model: _vm.form, rules: _vm.rules },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "description",
                                      label: "内容",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请选择内容" },
                                      model: {
                                        value: _vm.form.description,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "description", $$v)
                                        },
                                        expression: "form.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "permission",
                                      label: "范围",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { placeholder: "请选择范围" },
                                        model: {
                                          value: _vm.form.permission,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "permission",
                                              $$v
                                            )
                                          },
                                          expression: "form.permission",
                                        },
                                      },
                                      _vm._l(
                                        _vm.config
                                          .workBillRemarkPermissionOptions,
                                        function (option) {
                                          return _c("el-option", {
                                            key: option.value,
                                            attrs: {
                                              label: option.label,
                                              value: option.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "label" }, [
                                  _vm._v("上传附件"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "form-item" },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          action: `/backEnd/api/${_vm.config.supplyCode}/File/uploadFile?source=5`,
                                          multiple: "",
                                          limit: 3,
                                          "on-success": _vm.handleSuccess,
                                          "on-error": _vm.handleError,
                                          "on-progress": _vm.handleProgress,
                                          "before-upload": _vm.handleUpload,
                                          "show-file-list": false,
                                          "file-list": _vm.fileList,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          { staticClass: "btn" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                className: "icon",
                                                iconClass: "upload2",
                                              },
                                            }),
                                            _vm._v("选择文件上传"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "file-list" },
                                      _vm._l(
                                        _vm.fileList,
                                        function (file, $key) {
                                          return _c(
                                            "div",
                                            {
                                              key: $key,
                                              staticClass: "file-item",
                                            },
                                            [
                                              file.percent !== 100
                                                ? [
                                                    _c("el-progress", {
                                                      attrs: {
                                                        type: "circle",
                                                        "stroke-width": 4,
                                                        percentage:
                                                          file.percent,
                                                        width: 40,
                                                      },
                                                    }),
                                                  ]
                                                : [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "file-link",
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          attrs: {
                                                            className: "icon2",
                                                            iconClass:
                                                              "file-link",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                              _c(
                                                "div",
                                                { staticClass: "file-des" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "file-name",
                                                    },
                                                    [_vm._v(_vm._s(file.name))]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "file-size",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatSize")(
                                                            file.size
                                                          )
                                                        ) + " mb"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "opt" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteFile(
                                                          $key
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "footer" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeWorkBill("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "update-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadBtn("ruleForm")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-refresh" }),
                                    _vm._v(" 更新 "),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "zhuanjiao",
                                    on: {
                                      click: function ($event) {
                                        return _vm.zhuanjiao("ruleForm")
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        iconClass: "zhuanjiao",
                                        className: "icon",
                                      },
                                    }),
                                    _vm._v("转交"),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "success",
                                    on: {
                                      click: function ($event) {
                                        return _vm.passWorkBill("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("通过")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.remarkList, function (item, $index) {
                    return _c("div", { key: $index, staticClass: "panel" }, [
                      _c("div", { staticClass: "point-content" }, [
                        _c("div", {
                          class: {
                            acitve:
                              _vm.rowData.status < 2
                                ? _vm.isCurrentHolderName && $index === 0
                                : $index === 1,
                            "top-line":
                              (_vm.rowData.status < 2 &&
                                _vm.isCurrentHolderName) ||
                              $index > 0,
                          },
                        }),
                        _c("div", { staticClass: "point" }),
                        _c("div", {
                          class: {
                            acitve:
                              _vm.rowData.status < 2
                                ? !_vm.isCurrentHolderName &&
                                  $index === 0 &&
                                  $index < _vm.remarkList.length - 1
                                : $index === 0 &&
                                  $index < _vm.remarkList.length - 1,
                            "bottom-line": $index < _vm.remarkList.length - 1,
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          style: {
                            "margin-top":
                              $index === 0 && !_vm.isCurrentHolderName
                                ? "0"
                                : "10px",
                          },
                        },
                        [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("p", { staticClass: "des" }, [
                            _c("span", [_vm._v(_vm._s(item.createdName))]),
                            _c("span", { staticClass: "date-time" }, [
                              _vm._v(
                                "处理时间：" +
                                  _vm._s(
                                    item.createdOn
                                      ? item.createdOn.slice(
                                          0,
                                          item.createdOn.length - 3
                                        )
                                      : ""
                                  )
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "des" }, [
                            _c("span", [_vm._v("描述：")]),
                            _c("span", { staticClass: "des-value" }, [
                              _vm._v(_vm._s(item.description)),
                            ]),
                          ]),
                          item.systemFileIds
                            ? _c(
                                "p",
                                {
                                  staticClass: "des downLoad",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downLoad(
                                        item.systemFileIds,
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      className: "icon",
                                      iconClass: "file-link",
                                    },
                                  }),
                                  _c("span", { staticClass: "link" }, [
                                    _vm._v(" 附件下载 "),
                                    _c("span", { staticClass: "file-qun" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatFile")(
                                            item.systemFileIds
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ])
                  }),
                ],
                2
              ),
              _c("FileDownloadDialog", {
                attrs: {
                  dialogVisible: _vm.dialogVisible,
                  workOrderRemarkId: _vm.workOrderRemarkId,
                },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible = false
                  },
                },
              }),
              _c("CustomersDialog", {
                attrs: { dialogVisible: _vm.dialogVisible1 },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible1 = false
                  },
                  ok: _vm.ok,
                },
              }),
              _c("OtherDataDialog", {
                attrs: {
                  tableData: _vm.jsonData,
                  dialogVisible: _vm.dialogVisible2,
                },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible2 = false
                  },
                  ok: function ($event) {
                    _vm.dialogVisible2 = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }