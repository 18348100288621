<template>
  <div v-show="dialogVisible" class="oms-dialog-wrapper">
    <div class="oms-dialog">
      <div class="oms-dialog-body">
        <el-form ref="ruleForm" class="form" :model="form" :rules="rules">
          <el-form-item prop="des" label="选择转交用户">
            <el-select
              v-model="form.userId"
              style="width: 100%"
              placeholder="请选择"
            >
              <el-option
                v-for="item in userOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="oms-dialog-footer">
        <div class="btn cancel" @click="cancel('form')">取 消</div>
        <div class="btn ok" @click="ok('ruleForm')">确 定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserForType } from '@/api/system'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        form: {
          userId: ''
        },
        rules: {
          userId: [{ required: true, message: '请选择用户', trigger: 'change' }]
        },
        userOptions: []
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async getList() {
      try {
        const res = await getUserForType({})
        if (res.success) {
          this.userOptions = res.data.map(item => {
            return {
              value: item.id,
              label: item.nameCH
            }
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    ok(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.$emit('ok', { userId: this.form.userId })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.oms-dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
  width: 600px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  .oms-dialog {
    width: 360px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 20px;
    margin: 0 auto;
  }
  .oms-dialog-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .title {
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
    .close {
      font-size: 20px;
      color: #999999;
      cursor: pointer;
      line-height: 40px;
    }
  }
  .oms-dialog-body {
    width: 100%;
  }
  .oms-dialog-footer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    .btn {
        width: 80px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        text-align: center;
        cursor: pointer;
        &+.btn {
            margin-left: 10px;
        }
    }
    .cancel {
        background: #EEEEEE;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
    }
    .ok {
background: #2395F3;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
color: #FFFFFF;
        line-height: 40px;
    }
  }
}
</style>
